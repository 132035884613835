import { graphql, useStaticQuery } from "gatsby"

type UseSiteMetadataProps = {
  site: {
    siteMetadata: {
      siteTitle: string
      siteTitleAlt: string
      siteHeadline: string
      siteUrl: string
      siteDescription: string
      siteLanguage: string
      siteImage: string
      author: {
          name: string
          email: string
      }
      social: {
          github: string
          twitter: string
      }
      [key: string]: unknown
    }
  }
}

const useSiteMetadata = () => {
  const data = useStaticQuery<UseSiteMetadataProps>(graphql`
  query siteMetadata {
    site {
      siteMetadata {
        siteTitle
        siteTitleAlt
        siteHeadline
        siteUrl
        siteDescription
        siteLanguage
        siteImage
        author {
          name
          email
        }
        social {
          github
          twitter
        }
      }
    }
  }
  `)

  return data.site.siteMetadata
}

export default useSiteMetadata